import { isToday } from './index'

import {
	testStatus,
	paymentMethods,
	idPhotoStatus,
	eventCategory,
	applicationStatus,
	contentsMarketStatus,
} from '@/helpers'

export const kFormatter = (num, fraction = 2) =>
	num > 999 ? `${(num / 1000).toFixed(fraction)}k` : num

export const title = (value, replacer = ' ') => {
	if (!value) return ''
	const str = value.toString()

	const arr = str.split(replacer)
	const capitalizedArray = []
	arr.forEach(word => {
		const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
		capitalizedArray.push(capitalized)
	})

	return capitalizedArray.join(' ')
}

export const avatarText = value => {
	if (!value) return ''
	const nameArray = value.split(' ')

	return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
	value,
	formatting = { month: 'short', day: 'numeric', year: 'numeric' },
) => {
	if (!value) return value

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value)
	let formatting = { month: 'short', day: 'numeric' }

	if (toTimeForCurrentDay && isToday(date)) {
		formatting = { hour: 'numeric', minute: 'numeric' }
	}

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const resolveApplicationStatus = (
	category,
	status,
	isRetake,
	isCharged,
) => {
	let _status =
		applicationStatus[status]?.name || applicationStatus.DEFAULT.name
	if (
		category !== eventCategory.LICENSE_TEST.value &&
		_status === applicationStatus.APPLIED.name
	) {
		if (isRetake) {
			_status = '재수강 신청완료'
		} else {
			if (isCharged) {
				_status = '결제대기'
			} else {
				_status = '신청완료'
			}
		}
	}
	return _status
}

export const resolveApplicationStatusVariant = (
	category,
	status,
	isRetake,
	isCharged,
) => {
	let statusVariant

	if (status === applicationStatus.APPLIED.value) {
		if (isRetake) {
			statusVariant = 'primary'
		} else {
			statusVariant = 'primary'
			if (isCharged) {
				statusVariant = 'warning'
			}
		}
	} else if (status === applicationStatus.CANCELED.value) {
		statusVariant = 'error'
	} else if (status === applicationStatus.PAID.value) {
		statusVariant = 'info'
	} else if (status === applicationStatus.DONE.value) {
		statusVariant = 'secondary'
	}

	return statusVariant
}

export const resolveTestStatus = status => {
	return testStatus[status]?.name
}

export const resolveTestStatusVariant = status => {
	let statusVariant

	if (status === testStatus.PASS.value) {
		statusVariant = 'success'
	} else if (status === testStatus.FAIL.value) {
		statusVariant = 'error'
	}
	return statusVariant
}

export const resolveIdPhotoStatus = status => {
	return idPhotoStatus[status]?.name
}

export const resolveIdPhotoStatusVariant = status => {
	let statusVariant

	if (status === idPhotoStatus.CONFIRMED.value) {
		statusVariant = 'success'
	} else if (status === idPhotoStatus.UPLOADED.value) {
		statusVariant = 'primary'
	} else if (status === idPhotoStatus.REJECTED.value) {
		statusVariant = 'error'
	}
	return statusVariant
}

export const resolveOrderStatus = order => {
	let status

	if (order.canceled) {
		status = '주문 취소'
	} else {
		if (order.paid) {
			status = '결제 완료'
		} else {
			status = '결제 대기'
		}
	}

	return status
}

export const resolvePaymentMethod = paymentMethod => {
	return paymentMethods[paymentMethod?.toUpperCase()]?.name
}

export const isEmptyObject = _object => {
	return Object.keys(_object).length === 0 && _object.constructor === Object
}

export const resolveContentsMarketStatus = status => {
	return contentsMarketStatus[status]?.name || contentsMarketStatus.READY.name
}

export const resolveContentsMarketStatusVariant = status => {
	let statusVariant = 'transparent'

	if (status === contentsMarketStatus.APPROVED.value) {
		statusVariant = 'success'
	} else if (status === contentsMarketStatus.DISAPPROVED.value) {
		statusVariant = 'secondary'
	} else if (status === contentsMarketStatus.READY.value) {
		statusVariant = 'info'
	}

	return statusVariant
}
