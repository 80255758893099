<template>
	<div class="px-3">
		<v-row class="mt-1 mb-2">
			<v-col class="px-0">
				<div class="d-flex align-center">
					<router-link
						:to="{
							name: 'mypage-market-order',
							params: {
								orderId: order.id,
							},
						}"
						class="text-decoration-none"
					>
						<h3 style="font-weight: 500">
							<v-icon style="position: relative; bottom: 2px">
								mdi-card-multiple-outline
							</v-icon>
							상품
						</h3>
					</router-link>
					<span class="mx-1">&#62;</span>
					<h3>결제완료</h3>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>결제 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									결제 수단
								</v-list-item-subtitle>
								<v-list-item-title v-if="order">
									{{ resolvePaymentMethod(order.payMethod) }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							two-line
							v-if="order.vaccountName && order.vaccountNumber"
						>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									입금 은행
								</v-list-item-subtitle>
								<v-list-item-title v-if="order">
									{{ order.vaccountName }}
									<br />
									{{ order.vaccountNumber }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									{{ isVBank ? '입금하실 금액' : '결제 금액' }}
								</v-list-item-subtitle>
								<v-list-item-title v-if="order">
									<div v-if="order.amount > 0">
										<span class="amount primary--text">
											{{ order.amount | commaFormat }} 원
										</span>
									</div>
									<div v-else>
										<span class="amount primary--text">무료</span>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>신청 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									상품명
								</v-list-item-subtitle>
								<v-list-item-title v-if="order && order.title">
									{{ order.title }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									이름
								</v-list-item-subtitle>
								<v-list-item-title>{{ profile.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									이메일
								</v-list-item-subtitle>
								<v-list-item-title>{{ profile.email }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									휴대폰번호
								</v-list-item-subtitle>
								<v-list-item-title>{{ profile.phoneNumber }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-card-actions class="px-0 d-flex flex-column">
						<v-btn
							large
							color="primary"
							class="full-width font-weight-bold"
							:to="{ name: 'mypage-license' }"
						>
							상품 목록으로 가기
						</v-btn>
						<v-btn
							large
							color="secondary"
							class="full-width font-weight-bold mt-1 ml-0"
							:to="{ name: 'mypage-market-order' }"
						>
							주문 내역으로 가기
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@core/utils'
import { resolvePaymentMethod } from '@core/utils/filter'

import MarketOrderService from '@/services/MarketOrderService'

export default {
	setup() {
		const profile = computed(() => store.getters['user/getMeDetail'])
		const { route } = useRouter()
		const order = ref({})
		const orderId = route.value.params.orderId

		const getMarketOrder = async () => {
			order.value = await MarketOrderService.getMarketOrder(orderId)
		}
		getMarketOrder(orderId)

		window.scrollTo(0, 0)

		const isVBank = computed(() => {
			return order?.vaccountName && order?.vaccountNumber
		})

		return {
			order,
			profile,

			isVBank,
			resolvePaymentMethod,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button {
	width: 100%;
	max-width: 100%;
	min-height: 48px;
}

.full-width {
	width: 100%;
	max-width: 100%;
}
</style>
